import ask from "./ask.jpg";
import hkl from "./hkl.jpg";
import umleerer from "./umleerer.jpg";
import kasten from "./kasten.jpg";

import citycontainer from "./ask/3,0 m³ City-Container.jpg";
import offen3 from "./ask/3,0 m³ offen.jpg";
import offen5 from "./ask/5,0 m³ offen.jpg";
import offen7 from "./ask/7,0 m³ offen.jpg";
import deckel7 from "./ask/7,0 m³ Deckel.jpg";
import deckel10 from "./ask/10,0 m³ Deckel.jpg";
import klappe7 from "./ask/7,0 m³ mit Klappe.jpg";
import schlamm7 from "./ask/7,0 m³ Schlammmulde.jpg";
import schlamm10 from "./ask/10,0 m³ Schlammmulde.jpg";
import kran7 from "./ask/7,0 m³ Kran-Container.jpg";

import ladeplat from "./hkl/Ladeplattform.jpg";
import hkl15 from "./hkl/15,0 m³.jpg";
import hkl40 from "./hkl/40,0 m³.jpg";
import press10 from "./hkl/10,0 m³ Selbstpresscontainer.jpg";
import press20 from "./hkl/20,0 m³ Selbstpresscontainer.jpg";

import uml11 from "./umleerer/1,1 m³.jpg";
import uml3 from "./umleerer/3,0 m³.jpg";
import uml5 from "./umleerer/5,0 m³.jpg";
import uml7 from "./umleerer/7,0 m³.jpg";

import l240 from "./kasten/240 L.jpg";
import l240akten from "./kasten/240 L (Akten).jpg";
import l670 from "./kasten/670 L.jpg";

export const images = {
    ask,
    hkl,
    umleerer,
    kasten,
    "3,0 m³ City-Container": citycontainer,
    "3,0 m³ offen": offen3,
    "5,0 m³ offen": offen5,
    "7,0 m³ offen": offen7,
    "7,0 m³ Deckel": deckel7,
    "10,0 m³ Deckel": deckel10,
    "7,0 m³ mit Klappe": klappe7,
    "7,0 m³ Schlammmulde": schlamm7,
    "10,0 m³ Schlammmulde": schlamm10,
    "7,0 m³ Kran-Container": kran7,
    Ladeplattform: ladeplat,
    "15,0 m³": hkl15,
    "40,0 m³": hkl40,
    "10,0 m³ Selbstpresscontainer": press10,
    "20,0 m³ Selbstpresscontainer": press20,
    "1,1 m³": uml11,
    "3,0 m³": uml3,
    "5,0 m³": uml5,
    "7,0 m³": uml7,
    "240 L": l240,
    "240 L (Akten)": l240akten,
    "670 L": l670,
};
