import React from "react";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "./dx-styles.scss";

import Content from "./Content";
import { useScreenSizeClass } from "./utils/media-query";

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <div className={`app ${screenSizeClass}`}>
      <Content />
    </div>
  );
}
