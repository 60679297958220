import React from "react";

import "./SubHeader.scss";
import background from "../../images/header_small_wud.png";
import subbackground from "../../images/header_image_wud_ausstattung.png";

export default function Subheader() {
  return (
    <div className="subheader">
      <div className="title">
        <h2>Container bestellen</h2>
        <img src={background} alt="Background" className="background" />
        <img src={subbackground} alt="Subbackground" className="subbackground" />
      </div>

    </div>
  );
}
