import React from "react";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Subheader from "./components/subheader/SubHeader";
import ContainerForm from "./components/container-form/ContainerForm";

export default function Content() {
  return (
    <React.Fragment>
      <Header />
      <Subheader />
      <div className="content">
        <ContainerForm />
      </div>
      <Footer>
        <div className="footer-text">
          www.wud-entsorgung.de copyright 2023 -
          <a
            className="footer-link"
            href="http://www.wud-entsorgung.de/fusszeile/impressum"
          >
            Impressum
          </a>
          <a
            className="footer-link"
            href="http://www.wud-entsorgung.de/fusszeile/agb"
          >
            AGB
          </a>
          <a
            className="footer-link"
            href="http://www.wud-entsorgung.de/standards/datenschutzerklaerung"
          >
            Datenschutzerklärung
          </a>
          <a
            className="footer-link"
            href="http://www.wud-entsorgung.de/kontakt"
          >
            Kontakt
          </a>
        </div>
      </Footer>
    </React.Fragment>
  );
}
