import React /*, {useState, useEffect}*/ from "react";
import logo from "../../images/logo_wud.png"

import "./Header.scss";

export default function Header() {
  return (
    <header className={"header-component"}>
      <div className={"header-toolbar"}>
        <img src={logo} alt="Logo" className={"header-logo"} />
      </div>
    </header>
  );
}
