import React, { useState, useRef } from "react";
import Form, {
  Item,
  Label,
  RequiredRule,
  EmailRule,
  GroupItem,
  ButtonItem,
  ButtonOptions,
  PatternRule,
} from "devextreme-react/form";
import TextBox from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

import "./ContainerForm.scss";
import { images } from "../../images/container/container-img-manager";

const contractTypes = [
  "Stellen",
  "Tauschen",
  "Abholen",
  "Leeren (nur Umleerbehälter)",
];

const containerTypes = [
  {
    id: 0,
    name: "Absetzcontainer",
    img: images.ask,
  },
  {
    id: 1,
    name: "Hakenliftcontainer",
    img: images.hkl,
  },
  {
    id: 2,
    name: "Umleerbehälter",
    img: images.umleerer,
  },
  {
    id: 3,
    name: "Kastenwagenbehälter",
    img: images.kasten,
  },
];

const containerSizes = [
  {
    type: "Absetzcontainer",
    sizes: [
      {
        name: "3,0 m³ City-Container",
        size: "h:1,06m b:1,63m l:2m",
        img: images["3,0 m³ City-Container"],
      },
      {
        name: "3,0 m³ offen",
        size: "h:1,1m b:1,63m l:3m",
        img: images["3,0 m³ offen"],
      },
      {
        name: "5,0 m³ offen",
        size: "h:1,3m b:1,63m l:3,55m",
        img: images["5,0 m³ offen"],
      },
      {
        name: "7,0 m³ offen",
        size: "h:1,5m b:1,63m l:3,55m",
        img: images["7,0 m³ offen"],
      },
      {
        name: "7,0 m³ mit Klappe",
        size: "h:1,5m b:1,63m l:3,55m",
        img: images["7,0 m³ mit Klappe"],
      },
      {
        name: "7,0 m³ Kran-Container",
        size: "h:1,5m b:1,63m l:3,55m",
        img: images["7,0 m³ Kran-Container"],
      },
      {
        name: "7,0 m³ Deckel",
        size: "h:1,5m b:1,63m l:4,2m",
        img: images["7,0 m³ Deckel"],
      },
      {
        name: "10,0 m³ Deckel",
        size: "h:1,8m b:1,63m l:4,2m",
        img: images["10,0 m³ Deckel"],
      },
      {
        name: "7,0 m³ Schlammmulde",
        size: "h:1,5m b:1,63m l:4,2m",
        img: images["7,0 m³ Schlammmulde"],
      },
      {
        name: "10,0 m³ Schlammmulde",
        size: "h:1,8m b:1,63m l:4,2m",
        img: images["10,0 m³ Schlammmulde"],
      },
    ],
  },
  {
    type: "Hakenliftcontainer",
    sizes: [
      {
        name: "Ladeplattform",
        size: "h:1,35m b:2,5m l:6,5m",
        img: images["Ladeplattform"],
      },
      { name: "15,0 m³", size: "h:1,1m b:2,5m l:6,5m", img: images["15,0 m³"] },
      { name: "40,0 m³", size: "h:2,5m b:2,5m l:7,5m", img: images["40,0 m³"] },
      {
        name: "10,0 m³ Selbstpresscontainer",
        size: "h:2,5m b:1,9m l:4,5m",
        img: images["10,0 m³ Selbstpresscontainer"],
      },
      {
        name: "20,0 m³ Selbstpresscontainer",
        size: "h:2,5m b:2,5m l:6,8m",
        img: images["20,0 m³ Selbstpresscontainer"],
      },
    ],
  },
  {
    type: "Umleerbehälter",
    sizes: [
      {
        name: "1,1 m³",
        size: "h:1,48m b:1,38m l:1,21m",
        img: images["1,1 m³"],
      },
      { name: "3,0 m³", size: "h:1,47m b:2,3m l:1,35m", img: images["3,0 m³"] },
      { name: "5,0 m³", size: "h:1,47m b:2,3m l:2,68m", img: images["5,0 m³"] },
      {
        name: "7,0 m³",
        size: "h:1,47m b:2,68m l:2,68m",
        img: images["7,0 m³"],
      },
    ],
  },
  {
    type: "Kastenwagenbehälter",
    sizes: [
      { name: "240 L", size: "h:1,06m b:0,58m l:0,72m", img: images["240 L"] },
      {
        name: "240 L (Akten)",
        size: "h:1,06m b:0,58m l:0,72m",
        img: images["240 L (Akten)"],
      },
      { name: "670 L", size: "h:0,8m b:1,2m l:1m", img: images["670 L"] },
    ],
  },
];

const containerContents = [
  {
    id: 150101,
    name: "Verpackung aus Papier u. Pappe",
    type: ["Absetzcontainer", "Hakenliftcontainer", "Umleerbehälter"],
  },
  {
    id: 150102,
    name: "Verpackungen aus Kunststoff",
    type: ["Absetzcontainer", "Hakenliftcontainer", "Umleerbehälter"],
  },
  { id: 150103, name: "Verpackungen aus Holz", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  {
    id: 150106,
    name: "Gemischte Verpackungen",
    type: ["Absetzcontainer", "Hakenliftcontainer", "Umleerbehälter"],
  },
  {
    id: 170107,
    name: "Gemische aus Beton, Fliesen, Ziegeln",
    type: ["Absetzcontainer", "Hakenliftcontainer"],
  },
  { id: 170201, name: "Holz (Bauholz)", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  { id: 170204, name: "Holz,Glas u.Kunststoffe, die..", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  { id: 170302, name: "Bitumengemische mit Ausnahme", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  { id: 170603, name: "Anderes Dämmmaterial,das aus", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  {
    id: 170605,
    name: "asbesthaltige Baustoffe (Big-Bag verpackt)",
    type: ["Absetzcontainer", "Hakenliftcontainer"],
  },
  { id: 170802, name: "Baustoffe auf Gipsbasis", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  { id: 170904, name: "Gemischte Bau-u. Abbruchabfälle", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  {
    id: 200101,
    name: "Akten zur Vernichtung",
    type: ["Absetzcontainer", "Hakenliftcontainer", "Umleerbehälter", "Kastenwagenbehälter"],
  },
  {
    id: 200108,
    name: "biologisch abbaubare Küchen- und Kantinenabfälle, Speiseabfälle",
    type: ["Absetzcontainer", "Kastenwagenbehälter"],
  },

  {
    id: 200201,
    name: "biologisch abbaubare Abfälle (Grünschnitt)",
    type: ["Absetzcontainer", "Hakenliftcontainer"],
  },
  { id: 200301, name: "Gemischte Siedlungsabfälle", type: ["Absetzcontainer", "Hakenliftcontainer"] },
  { id: 200307, name: "Sperrmüll", type: ["Absetzcontainer", "Hakenliftcontainer"] },
];

const getNextWorkDay = (date) => {
  let newDate = new Date(date);
  let day = newDate.getDay();
  if (day === 6) {
    newDate.setDate(newDate.getDate() + 2);
  } else {
    newDate.setDate(newDate.getDate() + 1);
  }
  return newDate;
};  

const getStartofTheDay = (date) => {
  let newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

let canSendMail = true;
const allowMail = () => {
  if (canSendMail) {
    canSendMail = false;
    return true;
  }
  return false;
};

const buildFormData = (formData, data) => {
  Object.keys(data).forEach((key) => {
    let value = data[key] == null ? "" : data[key];
    if (typeof value === "object") {
      value = value.name;
    }
    formData.append(key, value);
  });
};

export default function ContainerForm(props) {
  const formData = useRef({
    customerNr: "",
    customerName: "",
    customeremail: "",
    customerstreet: "",
    customerstreetNr: "",
    customerPLZ: "",
    contactName: "",
    contactTel: "",
    delieveryStreet: "",
    delieveryStreetNr: "",
    delieveryPLZ: "",
    seperateDelivery: false,
    delieveryRemarks: "",
    contractType: "",
    containerType: "",
    containerSize: "",
    containerContent: "",
    deliveryTime: new Date().toLocaleString("de-DE"),
    containerRemarks: "",
  });
  const [toastConfig, setToastConfig] = useState({
    message: "Hi",
  });
  const [popupVisible, setPopupVisible] = useState(false);
  const [ignore, forceUpdate] = useState(false);
  const [filterdContainerTypes, setFilterdContainerTypes] =
    useState(containerTypes);
  const [containerSize, setContainerSize] = useState([]);
  const [containerContent, setContainerContent] = useState(containerContents);
  // Function for submitting the form
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!allowMail()) {
      console.log("Mail already sent");
      return;
    }

    if (formData.current.containerType === "") {
      setToastConfig({
        message: "Bitte wählen Sie einen Container Typ aus",
      });
      setPopupVisible(true);
      return;
    }
    if (formData.current.containerSize === "") {
      setToastConfig({
        message: "Bitte wählen Sie eine Container Größe aus",
      });
      setPopupVisible(true);
      return;
    }
    if (formData.current.containerContent === "") {
      setToastConfig({
        message: "Bitte wählen Sie den Container Inhalt aus",
      });
      setPopupVisible(true);
      return;
    }
    if (formData.current.auftragTermin === "") {
      setToastConfig({
        message: "Bitte wählen Sie einen Auftrags Termin aus",
      });
      setPopupVisible(true);
      return;
    }
    if (
      formData.current.seperateDelivery &&
      formData.current.delieveryStreet === ""
    ) {
      alert(
        "Bitte geben Sie eine Lieferadresse an oder deaktivieren Sie die Option 'Separate Lieferadresse'"
      );
      return;
    }

    forceUpdate(!ignore);
    console.log("Sending mail", popupVisible);

    let data = new FormData();
    formData.current.sendingDate = new Date().toLocaleString("de-DE");
    buildFormData(data, formData.current);

    
    setToastConfig({
      message: "Vielen Dank für Ihre Bestellanfrage. Wir werden Sie schnellmöglich kontaktieren",
    });
    setPopupVisible(true);
    setTimeout(() => {

    fetch("sendmail.php", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (response.status === 200) {
          setToastConfig({
            message: "Bestellung erfolgreich gesendet",
          });
          setPopupVisible(true);
          formData.current = {
            customerNr: "",
            customerName: "",
            customeremail: "",
            customerstreet: "",
            customerstreetNr: "",
            customerPLZ: "",
            contactName: "",
            contactTel: "",
            delieveryStreet: "",
            delieveryStreetNr: "",
            delieveryPLZ: "",
            seperateDelivery: false,
            delieveryRemarks: "",
            contractType: "",
            containerType: "",
            containerSize: "",
            containerContent: "",
            deliveryTime: "",
            containerRemarks: "",
          };
          canSendMail = true;
        } else {
          setToastConfig({
            message: "Ein Fehler ist beim Senden der Bestellung aufgetretten",
          });
          setPopupVisible(true);
        }
      })
      .catch((error) => {
        setToastConfig({
          message: "Ein Fehler ist beim Senden der Bestellung aufgetretten",
        });
        setPopupVisible(true);
      });
    }, 1000);
  };

  const seperateDeliveryEditorOptions = {
    text: "Separate Lieferadresse",
    value: formData.current.seperateDelivery,
    onValueChanged: (e) => {
      forceUpdate(!ignore);
    },
  };

  const contractTypesEditorOptions = {
    stylingMode: "filled",
    placeholder: "Auftragsart",
    dataSource: contractTypes,
    onValueChanged: (e) => {
      if (e.value === "Leeren (nur Umleerbehälter)") {
        setFilterdContainerTypes(
          containerTypes.filter((x) => x.name === "Umleerbehälter")
        );
        formData.current.containerType = 2;
      } else {
        setFilterdContainerTypes(containerTypes);
      }
      forceUpdate(!ignore);
    },
  };

  const setContainerTyp = (e) => {
    formData.current.containerType = e.value;
    setContainerSize(
      containerSizes.filter((item) => item.type === e.value.name)[0].sizes
    );
    setContainerContent(
      containerContents.filter((item) => item.type.includes(e.value.name))
    );
  };

  const setContainerSizes = (e) => {
    formData.current.containerSize = e.value;
  };

  const setContainerContents = (e) => {
    formData.current.containerContent = e.value;
    if (formData.current.containerType === "") {
      let possibleTyps = containerContents.filter(
        (item) => item.id === e.value.id
      )[0].type;
      setFilterdContainerTypes(
        containerTypes.filter((x) => possibleTyps.includes(x.name))
      );
    }
  };

  const setDeliveryTime = (e) => {
    formData.current.deliveryTime = e.value;
  };

  return (
    <form
      className={"container-form"}
      id="container-form"
      onSubmit={onSubmit}
      action="http://localhost:8000/sendmail.php"
      method="post"
    >
      <Form formData={formData.current}>
        <GroupItem minColWidth={400} colCount={"auto"}>
          <GroupItem caption={"Kundendaten"}>
            <Item
              dataField={"customerNr"}
              editorType={"dxTextBox"}
              editorOptions={customerNrOptions}
            >
              <Label visible={false} />
              <PatternRule
                message="Die Kundennummer muss 5 Zahlen lang sein"
                pattern={/^[0-9]{5}$/}
              />
            </Item>
            <Item
              dataField={"customerName"}
              editorType={"dxTextBox"}
              editorOptions={nameEditorOptions}
            >
              <RequiredRule message="Pflichtfeld" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"customeremail"}
              editorType={"dxTextBox"}
              editorOptions={emailEditorOptions}
            >
              <EmailRule message="Keine gültige E-Mail Adresse" />
              <Label visible={false} />
            </Item>
            <GroupItem colCount={2}>
              <Item
                dataField={"customerstreet"}
                editorType={"dxTextBox"}
                editorOptions={streetEditorOptions}
              >
                <RequiredRule message="Pflichtfeld" />
                <Label visible={false} />
              </Item>
              <Item
                dataField={"customerstreetNr"}
                editorType={"dxTextBox"}
                editorOptions={streetNrEditorOptions}
              >
                <RequiredRule message="Pflichtfeld" />
                <Label visible={false} />
                <PatternRule
                  message="Bitte geben Sie eine gültige Haußnummer an (z.B. 1 oder 1a)"
                  pattern={/[1-9][0-9]{0,3}[a-z]{0,1}\s*$/}
                />
              </Item>
            </GroupItem>

            <Item
              dataField={"customerPLZ"}
              editorType={"dxTextBox"}
              editorOptions={plzEditorOptions}
            >
              <RequiredRule message="Pflichtfeld" />
              <Label visible={false} />
            </Item>
          </GroupItem>
          <GroupItem caption={"Lieferdaten"}>
            <Item
              dataField={"contactName"}
              editorType={"dxTextBox"}
              editorOptions={contactNameEditorOptions}
            >
              <RequiredRule message="Pflichtfeld" />
              <Label visible={false} />
              <PatternRule
                message="Bitte geben Sie einen gültigen Namen an (z.B. Max Mustermann)"
                pattern={/^[A-Z][a-z]* ([A-Z][a-z]* )*[A-Z][a-z]*/}
              />
            </Item>
            <Item
              dataField={"contactTel"}
              editorType={"dxTextBox"}
              editorOptions={contactTelEditorOptions}
            >
              <RequiredRule message="Pflichtfeld" />
              <Label visible={false} />
              <PatternRule
                message="Bitte geben Sie eine gültige Telefonnummer an (z.B. 01234 567890 / +49 1234 567890)"
                pattern={/^((\+[1-9][0-9]{0,1})|(0))( {0,1})([1-9][0-9 ]{9,})/}
              />
            </Item>
            <Item
              dataField={"seperateDelivery"}
              editorType={"dxCheckBox"}
              editorOptions={seperateDeliveryEditorOptions}
              disabled={false}
            >
              <Label visible={false} />
            </Item>
            {formData.current.seperateDelivery && (
              <GroupItem colCount={2}>
                <Item
                  dataField={"delieveryStreet"}
                  editorType={"dxTextBox"}
                  editorOptions={streetEditorOptions}
                >
                  <RequiredRule message="Pflichtfeld" />
                  <Label visible={false} />
                </Item>
                <Item
                  dataField={"delieveryStreetNr"}
                  editorType={"dxTextBox"}
                  editorOptions={streetNrEditorOptions}
                >
                  <RequiredRule message="Pflichtfeld" />
                  <Label visible={false} />
                  <PatternRule
                    message="Bitte geben Sie eine gültige Hausnummer an (z.B. 1 oder 1a)"
                    pattern={/[1-9][0-9]{0,3}[a-z]{0,1}\s*$/}
                  />
                </Item>
              </GroupItem>
            )}
            {formData.current.seperateDelivery && (
              <Item
                dataField={"delieveryPLZ"}
                editorType={"dxTextBox"}
                editorOptions={plzEditorOptions}
                disabled={!formData.current.seperateDelivery}
              >
                <Label visible={false} />
              </Item>
            )}
            <Item
              dataField={"delieveryRemarks"}
              editorType={"dxTextBox"}
              editorOptions={deliveryRemarksEditorOptions}
            >
              <Label visible={false} />
            </Item>
          </GroupItem>
        </GroupItem>
        <GroupItem caption={"Containerdaten"}>
          <Item
            dataField={"auftragsArt"}
            editorType={"dxSelectBox"}
            editorOptions={contractTypesEditorOptions}
          >
            <RequiredRule message="Pflichtfeld" />
          </Item>
          <Item dataField={"containerTyp"}>
            <SelectBox
              dataSource={filterdContainerTypes}
              displayExpr="name"
              fieldRender={ImgField}
              itemRender={ImgItem}
              onValueChanged={setContainerTyp}
              name="containerTyp"
            />
          </Item>
          <Item dataField={"containerGröße"}>
            <SelectBox
              dataSource={containerSize}
              displayExpr="name"
              fieldRender={ImgField}
              itemRender={ImgItem}
              onValueChanged={setContainerSizes}
              disabled={containerSize.length === 0}
              name="containerGröße"
            />
          </Item>
          <Item dataField={"containerInhalt"}>
            <SelectBox
              dataSource={containerContent}
              displayExpr="name"
              onValueChanged={setContainerContents}
              disabled={containerContent.length === 0}
              name="containerInhalt"
            />
          </Item>
          <Item dataField={"auftragTermin"}>
            {formData.current.containerType.name === "Umleerbehälter" && (
              <span className="dx-texteditor-input-container">
                <span className="dx-texteditor-input date-text">
                  Umleer-Behälter und Containertausch mit Kastenwagenbehälterwagen im
                  nächsten Turnus
                </span>
              </span>
            )}
            <Label text="Wunschtermin &nbsp;&nbsp; Datum, gewünschte Uhrzeit (unter Vorbehalt)" />
            <DateBox
              defaultValue={getNextWorkDay(new Date()).toISOString()}
              min={getStartofTheDay(getNextWorkDay(new Date()))}
              type="datetime"
              onValueChanged={setDeliveryTime}
              disabled={formData.current.containerType.name === "Umleerbehälter"}
              openOnFieldClick={true}
              disabledDates={(d) => d.date.getDay() === 0}
              name="auftragTermin"
            />
          </Item>
          <Item
            dataField={"containerRemarks"}
            editorType={"dxTextBox"}
            editorOptions={containerRemarksEditorOptions}
          >
            <Label visible={false} />
          </Item>
        </GroupItem>
        <Item cssClass="submit-warning">
          <p>Bestellungen sind ausschließlich mit Vorkasse möglich. Nach Versand Ihrer Anfrage wird unser Team Sie kontaktieren.</p>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            height={"50px"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">JETZT BESTELLANFRAGE VERSENDEN</span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <Popup
        visible={popupVisible}
        onHiding={() => setPopupVisible(false)}
        hideOnOutsideClick={true}
        dragEnabled={false}
        showCloseButton={false}
        container=".dx-viewport"
        shading={false}
        width={300}
        height={140}
        showTitle={false}
      >
        <Position my="center" collision="fit" from="#container-form" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: "OK",
            onClick: () => setPopupVisible(false),
          }}
        />
        <p style={{ margin: 0 }}>{toastConfig.message}</p>
      </Popup>
    </form>
  );
}

function ImgField(data) {
  return (
    <div className="custom-item">
      <img alt="" src={data && data.img} />
      <TextBox
        className="product-name"
        defaultValue={data && data.name + (data.size ? " " + data.size : "")}
        readOnly={true}
      />
    </div>
  );
}
function ImgItem(data) {
  return (
    <div className="custom-item">
      <img src={data.img} alt="" />
      <div className="product-name">{data.name}</div>
    </div>
  );
}

const customerNrOptions = {
  stylingMode: "filled",
  placeholder: "Kundennummer (optional)",
  mode: "text",
  maxLength: 5,
};
const nameEditorOptions = {
  stylingMode: "filled",
  placeholder: "Name mit Rechtsform",
  mode: "text",
  maxLength: 50,
};
const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "E-Mail (optional)",
  mode: "email",
};
const streetEditorOptions = {
  stylingMode: "filled",
  placeholder: "Straße",
  mode: "text",
};
const streetNrEditorOptions = {
  stylingMode: "filled",
  placeholder: "Hausnummer",
  mode: "text",
};
const plzEditorOptions = {
  stylingMode: "filled",
  placeholder: "LKZ, PLZ, Ort",
  mode: "text",
};
const contactNameEditorOptions = {
  stylingMode: "filled",
  placeholder: "Ansprechpartner",
  mode: "text",
  maxLength: 50,
};
const contactTelEditorOptions = {
  stylingMode: "filled",
  placeholder: "TelefonNr. des Ansprechpartner",
  mode: "text",
};
const deliveryRemarksEditorOptions = {
  stylingMode: "filled",
  placeholder: "Weitere Bemerkungen zur Lieferung",
  mode: "text",
};
const containerRemarksEditorOptions = {
  stylingMode: "filled",
  placeholder: "Weitere Bemerkungen zur Bestellung",
  mode: "text",
};
